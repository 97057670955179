import {Navigate, useLocation} from 'react-router-dom'
import {Unauthorized} from '../modules/Unauthorized';
import { RedirectToUrlDirect } from '../Utils/responseHandler';

const RequireAuth = ({ children }) => {
  var isAuthenticated = false; // your logic here
  const authToken = localStorage.getItem('authToken');
  const userRole = localStorage.getItem('userRole');
    if (userRole === 'Admin' && authToken) {
        isAuthenticated = true;
    }
    
    if (!isAuthenticated) {
        return RedirectToUrlDirect('/home'); 
        // <Unauthorized/>; 
        // <Navigate to="/login" state={{ from: location }} />;
    }
  return children;
}

export default RequireAuth;

