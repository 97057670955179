import React from 'react'
import { NavLink } from 'react-router-dom'

function AdminSidebar(props) {
  return (
    <div>
        {
            props.isAuthenticated?
                <nav className={props.sidebar} id="sidebar">
                    <ul className="nav">
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/dashboard">
                                <i className='icon-grid menu-icon'></i>
                                <span className="menu-title">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#companies" aria-expanded="false" aria-controls="companies">
                                <i className="bi bi-building sub-menu mr-3"></i>
                                <span className="menu-title">Company</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="companies">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/companies">
                                            <i className='bi bi-list sub-menu mr-1'></i>
                                            <span className="menu-title">List</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/addcompany">
                                            <i className='bi bi-plus sub-menu mr-1'></i>
                                            <span className="menu-title">Add</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#providers" aria-expanded="false" aria-controls="providers">
                                <i className="bi bi-boxes sub-menu mr-3"></i>
                                <span className="menu-title">Providers</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="providers">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/providers">
                                            <i className='bi bi-list sub-menu mr-1'></i>
                                            <span className="menu-title">List</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/addprovider">
                                            <i className='bi bi-plus sub-menu mr-1'></i>
                                            <span className="menu-title">Add</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/roles">
                                <i className='icon-grid menu-icon'></i>
                                <span className="menu-title">Role</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav> 
            : 
            ''
        }
    </div>
  )
}

export default AdminSidebar