import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import { getUrlId, RedirectToUrlDirect } from '../Utils/responseHandler';
import axios from 'axios';
import Swal from 'sweetalert2';
const _ = require('lodash');

export class EditCompany extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            title: "Extra Fields",
            formValues: [{
                name_firma: "", owner_name: "", short_name: "", status: "", phone: "", apikey:"", extra_fields: "", production: ""
            }],
            extraValues: [],
            label: "",
            messageError: "",
            messageSuccess: "",
            company_fields: [],
            isLoading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    mainChange(i, e) {
        let formValues = this.state.formValues;
        formValues[0][e.target.name] = e.target.value;
        this.state.data[e.target.name] = e.target.value;
        this.setState({ formValues: formValues });
    }
    handleCompanyValueChange(i, e) {
        let company_fields = this.state.company_fields;
        if (company_fields[i].id) {
            company_fields[i].value = e.target.value;

        } else {
            company_fields[i]['value'] = e.target.value;
        }
        this.setState({ company_fields });
    }
    handleCompanyNameChange(i, e) {
        let company_fields = this.state.company_fields;
        if (company_fields[i].id) {
            company_fields[i].name = e.target.value;

        } else {
            company_fields[i]['name'] = e.target.value;
        }
        this.setState({ company_fields });
    }

    addFormFields() {
        this.setState(({
            company_fields: [...this.state.company_fields, {}]
        }))
    }

    async removeFormFields(i, el) {
        if (!_.isEmpty(el[i])) {
            let res = await
                axios.delete(api_url.API_URL + 'companies/fields/' + el[i].id)
                    .then(response => { console.log(response) })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
        }
        let company_fields = this.state.company_fields;
        company_fields.splice(i, 1);
        this.setState({ company_fields });
    }

    async handleSubmit(e) {
        e.preventDefault();
        let output = [];
        let mainvalue = this.state.formValues[0];
        let extvalue = this.state.company_fields;
        mainvalue['extra_fields'] = extvalue;
        try {

            let res = await fetch(api_url.API_URL + 'companies/' + getUrlId(), {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mainvalue),
            });
            let resJson = await res.json();

            if (resJson.error === false) {
                this.setState({ messageError: JSON.stringify(resJson.data) });
                Swal.fire({
                    title: 'Error',
                    text: this.state.messageError,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
            if (resJson && resJson.success) {
                this.setState({ messageError: "Company has been updated !!" });
                Swal.fire({
                    title: 'Success',
                    text: this.state.messageSuccess,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(function() {
                    window.location.replace('/companies')
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ messageError: "Some error occured" });
            Swal.fire({
                title: 'Error',
                text: this.state.messageError,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }

    async fetchData() {
        const id = getUrlId();
        console.log(id);
        try {
            fetch(api_url.API_URL + 'companies/' + id)
                .then(response => response.json())
                .then(res => {
                    console.log(res.data)
                    this.setState({
                        data: res.data,
                        formValues: [{
                            name_firma: res.data.name_firma,
                            phone: res.data.phone,
                            owner_name: res.data.owner_name,
                            short_name: res.data.short_name,
                            apikey : res.data.apikey,
                            status: res.data.status,
                            production: res.data.production,
                        }],
                        company_fields: res.data.company_fields,
                        isLoading: false,
                    });
                }).catch(error => {
                    // console.error('Error fetching data:', error);
                    this.setState({ isLoading: false });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { name_firma, owner_name, short_name, status, phone, apikey, production, extra_fields } = this.state.formValues[0];
        const { extraValues } = this.state;
        const { data, company_fields, formValues, isLoading } = this.state;


        //console.log(this.state.extraValues)
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='card'>
                        <div className='modal-header'>
                            <h6>Edit Company</h6>
                        </div>
                        <div className='card-body registration_form'>
                            <form onSubmit={this.handleSubmit} >
                                <div className="modal-body">
                                    <div className='row' >
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Company Name</label>
                                                <input type="text" className="form-control" name="name_firma"
                                                    value={data.name_firma}
                                                    onChange={e => this.mainChange(formValues.name_firma, e)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Owner Name</label>
                                                <input type="text" className="form-control" name="owner_name"
                                                    value={data.owner_name}
                                                    onChange={e => this.mainChange(formValues.owner_name, e)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Phone</label>
                                                <input type="text" className="form-control" name="phone"
                                                    value={data.phone}
                                                    onChange={e => this.mainChange(formValues.phone, e)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Short Name</label>
                                                <input type="text" className="form-control" name="short_name" value={data.short_name}
                                                    onChange={e => this.mainChange(formValues.short_name, e)} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Status</label>
                                                <select className="form-select form-control" name="status"
                                                    onChange={e => this.mainChange(formValues.status, e)}
                                                    value={data.status}>
                                                    <option value="true">Active</option>
                                                    <option value="false">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Production Mode</label>
                                                <select className="form-select form-control" name="production"
                                                    onChange={e => this.mainChange(formValues.production, e)}
                                                    value={data.production}>
                                                    <option value="live">Live</option>
                                                    <option value="test">Test</option>
                                                </select>
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['production']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Api key</label>
                                                <input type="text" className="form-control" name="apikey" value={data.apikey}
                                                    onChange={e => this.mainChange(formValues.apikey, e)} />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.title ? <h4 className='text-left'>{this.state.title}</h4> : null}
                                    {company_fields.map((item, index) => (
                                        <div className='row form-group' key={index}>
                                            <div className={index ? 'col-lg-5' : 'col-lg-6'}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name='name'
                                                    value={(item.name) ?? ''}
                                                    onChange={(e) => this.handleCompanyNameChange(index, e)}
                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                {
                                                    (item.type == 'textarea') ?
                                                        <textarea type={(item.type) ? item.type : "text"}
                                                            className="form-control"
                                                            name='value'
                                                            value={item.value ?? ''}
                                                            onChange={(e) => this.handleCompanyValueChange(index, e)}
                                                        ></textarea>
                                                        :
                                                        <input
                                                            type={(item.type) ? item.type : "text"}
                                                            className="form-control"
                                                            name='value'
                                                            value={item.value}
                                                            onChange={(e) => this.handleCompanyValueChange(index, e)}
                                                        />
                                                }
                                            </div>
                                            {
                                                index ?
                                                    <div className='col-lg-1'>
                                                        <button type="button" className="btn btn-icon btn-rounded btn-warning remove float-right remove" onClick={() => this.removeFormFields(index, company_fields)}><i className='bi bi-trash-fill'></i></button>
                                                    </div>
                                                    : <div className='col-lg-1'></div>
                                            }
                                        </div>
                                    ))
                                    }
                                    <button className="btn btn-primary btn-icon btn-rounded float-right" type="button" onClick={() => this.addFormFields()}><i className='bi bi-plus'></i></button>
                                    <div className='clearfix'></div>
                                </div>

                                <div className="modal-footer">
                                    <button className="btn btn-primary float-left" type="submit">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

