import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api_url } from '../Constants/api_constant';
import { handleResponse } from '../Utils/responseHandler';
import logResponse from '../Utils/logger';

const SubscriberLogin = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('bishwa.ram@norwegiandigital.no');
  const [password, setPassword] = useState('');
  const [referalUrl, setReferalUrl] = useState('https://frontend.theloginhub.eu/subscriber/login');
  const [apikey, setApikey] = useState("li54m8auqgn7j3llo");
  // const [apikey, setApikey] = useState("gd54m8auqgn7j3llo");
  const [method, setMethod] = useState("");
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const getCompanies = async() =>{
    let res = await fetch(api_url.API_URL + 'companies/', {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    let resJson = await res.json();
    var data = [];
    resJson.data.forEach(element => {
      data.push([element.name_firma,element.apikey]);
    });
    console.log(data)
    return data;
  }

  useEffect(() => {
    var data = getCompanies();
    console.log(data);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const loginData = {
      email: usernameOrEmail,
      password: password,
      referalUrl: (referalUrl)? 'https://dittmagasin.no/api/info-token': referalUrl,
      // referalUrl: 'https://dittmagasin.no/api/info-token',
    };
    let loginMethod = (method)? method:'';
    var loginUrl = '';
    console.log(loginMethod)
    if(loginMethod.length > 0) {
      loginUrl = api_url.API_URL + 'login' + '?method='+ loginMethod;
    } else {
      loginUrl = api_url.API_URL + 'login';
    }
    console.log(loginUrl)
    try {
      
     
      let res = await
        axios({
          method: 'post',
          url: loginUrl,
          data: {
            email: usernameOrEmail,
            password: password,
            referalUrl: 'https://frontend.theloginhub.eu/subscriber/login'
            // referalUrl: referalUrl
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'apikey': apikey
          },
        });
        
      let response = res;

      if (response.status == 200) {
        if (response.data.data.token) {
          setMessage(response.data.data.token)
          setErrorMessage('')
        }
        if (response.data.data.url) {
          window.location.replace(response.data.data.url);
          setErrorMessage('')
        }
      } else {
        setErrorMessage(response.error.data.data.toUpperCase())
        setMessage('')
      }
    } catch (error) {
      console.log(error.response.data.data)
      setErrorMessage(error.response.data.data)
      setMessage('')
    }
  };


  return (
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center">
        <div className="row w-75 m-auto">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-center px-sm-5"></div>
            <div className='card p-5'>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              {
                message && <div className='alert alert-info'>{message}</div>
              }
              {
                errorMessage && <div className='alert alert-danger'>{errorMessage}</div>
              }

              <form onSubmit={handleLogin} className="pt-3">
                <div className="form-group">
                  <input type="text" className='form-control' placeholder="Username or Email" value={usernameOrEmail} onChange={(e) => setUsernameOrEmail(e.target.value)} />
                </div>
                <div className="form-group">
                  <input type="password" className='form-control' placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                  <input type="text" className='form-control' placeholder="apikey" value={apikey} onChange={(e) => setApikey(e.target.value)} />
                </div>
                <div className="form-group">
                  <input type="text" className='form-control' placeholder="method" value={method} onChange={(e) => setMethod(e.target.value)} />
                </div>
                <button type="submit" className='btn btn-success float-left'><i className='bi bi-box-arrow-in-down-right'></i> Sign in</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberLogin;
