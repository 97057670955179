import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import { getUrlId, RedirectToUrlDirect } from '../Utils/responseHandler';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, NavLink } from 'react-router-dom';
const _ = require('lodash');

export class ViewCompany extends Component {
    
    constructor(props) {
        super(props)              
        this.state = {  
            data: {}, 
            title : "Extra Fields" ,         
            formValues: [{ 
                name_firma: "", owner_name : "" ,short_name: "", status : "", phone: "", extra_fields: "" 
            }],
            extraValues: [],    
            label :"",                 
            messageError : "" ,
            messageSuccess: "",       
            company_fields: [],                  
            isLoading: true,                 
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }  

    mainChange(i, e) {
        let formValues = this.state.formValues;
        formValues[0][e.target.name] = e.target.value;
        this.state.data[e.target.name] = e.target.value;
        this.setState({formValues: formValues});
    }
    handleCompanyValueChange(i, e) {
        let company_fields = this.state.company_fields;
        if(company_fields[i].id) {
            company_fields[i].value = e.target.value;

        } else {
            company_fields[i]['value'] = e.target.value;
        }
        this.setState({ company_fields });
    }
    handleCompanyNameChange(i, e) {
        let company_fields = this.state.company_fields;
        if(company_fields[i].id) {
            company_fields[i].name = e.target.value;

        } else {
            company_fields[i]['name'] = e.target.value;
        }
        this.setState({ company_fields });
    }

    addFormFields() {
        this.setState(({
            company_fields: [...this.state.company_fields, {}]
        }))
    }
    
    async removeFormFields(i, el) {
        if(!_.isEmpty(el[i])){
            let res = await
            axios.delete(api_url.API_URL + 'companies/fields/' + el[i].id )
            .then(response => {console.log(response)})
            .catch(error => {
              console.error('Error fetching data:', error);
            });
        }
        let company_fields = this.state.company_fields;
        company_fields.splice(i, 1);
        this.setState({ company_fields });
    }
    
    async handleSubmit(e) {
        e.preventDefault();            
        let output = [];        
        let mainvalue = this.state.formValues[0]; 
        let extvalue =  this.state.company_fields;
        mainvalue['extra_fields'] = extvalue;  
        try {

                let res = await fetch(api_url.API_URL + 'companies/' + getUrlId(), {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(mainvalue),
                });
                let resJson = await res.json();

                if (resJson.error === false) {
                    this.setState({ messageError: JSON.stringify(resJson.data)});
                    Swal.fire({
                        title: 'Error',
                        text: this.state.messageError,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }); 
                }
                if (resJson && resJson.success) { 
                    this.setState({ messageError:  "Company has been updated !!"});                   
                    Swal.fire({
                        title: 'Success',
                        text: this.state.messageSuccess,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    // window.location.replace('/companies');
                }
        } catch (err) {
            console.log(err);
            this.setState({ messageError:  "Some error occured"});
            Swal.fire({
                title: 'Error',
                text: this.state.messageError,
                icon: 'error',
                confirmButtonText: 'OK'
            });     
        }
    }
    
    async fetchData() {
        const id = getUrlId();
        console.log(id);
        try {
            fetch(api_url.API_URL + 'companies/' + id)
            .then(response => response.json())
            .then(res => {
                console.log(res.data)
                this.setState({
                    data: res.data,
                    formValues: [{
                        name_firma: res.data.name_firma, 
                        phone: res.data.phone, 
                        owner_name: res.data.owner_name, 
                        short_name: res.data.short_name, 
                        status : res.data.status, 
                    }],
                    company_fields: res.data.company_fields,
                    isLoading: false,
                  });
            }).catch(error => {
                // console.error('Error fetching data:', error);
                this.setState({ isLoading: false });
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });    
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    render () { 
        const {name_firma, owner_name  ,short_name, status , phone, extra_fields} = this.state.formValues[0];
        const { extraValues } = this.state;
        const { data, company_fields, formValues , isLoading } = this.state;

        
        //console.log(this.state.extraValues)
        return (
            <div className="container"> 
                <div className="registration_form" >                                             
                    <div className="modal-header">
                        <h1 className="modal-title">Company Profile</h1>                                  
                    </div>
                    <div className="container"> 
                    <div className="registration_form" >                                             
                        <form onSubmit={this.handleSubmit} >
                            <div className="modal-body">                                        
                                    <div className='row' > 
                                        <table className='table table-stripe text-left'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='text-right'>Company Name</td>
                                                    <td>{data.name_firma}</td>
                                                </tr>
                                                <tr>
                                                <td className='text-right'>Owner Name</td>
                                                    <td>{data.owner_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-right'>Owner Name</td>
                                                    <td>{data.owner_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-right'>Phone</td>
                                                    <td>{data.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-right'>Short Name</td>
                                                    <td>{data.short_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-right'>Status</td>
                                                    <td>{(data.status)? 'Active': 'Blocked'}</td>
                                                </tr>
                                                {company_fields.map((item, index) => (
                                                     <tr>
                                                        <td className='text-right'>{item.name}</td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                )) }
                                                <tr>
                                                    <td className='text-right'></td>
                                                    <td>
                                                        <NavLink to={`/companies/edit/${data.id}`} className="btn btn-primary"><i className="bi bi-pencil-square"></i> Edit</NavLink>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>      
                                </div>
                        </form>
                    </div>
                </div>
                </div>
        </div>             
        );
    }   
}
 
