import React, { useEffect, useState } from 'react';
import LoginController from '../Controllers/LoginController';
import { NavLink, useNavigate } from 'react-router-dom';
import { RedirectToUrlDirect } from '../Utils/responseHandler';
import Swal from 'sweetalert2';
import Logo from '../logo.png';


const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setErrorMessage] = useState('');
  const [userRole, setUserRole] = useState('');
  const [companyId, setCompanyId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole');
    const companyId = localStorage.getItem('companyId');
    if (userRole) {
      setUserRole(userRole);
    }
    if (companyId) {
      setCompanyId(companyId);
    }
    if (authToken) {
      navigate('/home');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const loginData = {
      email: usernameOrEmail,
      password: password
    };
    await LoginController.loginUser(loginData, () => {
      setIsAuthenticated(true);
      RedirectToUrlDirect('/home'); // Redirect to home view
    }, setMessage, setErrorMessage);
  };


  return (
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth px-0">
        <div className="row w-100 m-auto">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-center py-5 px-sm-5">
                  <div className="brand-logo">
                    <img src={Logo} alt="logo"/>
                  </div>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                    {
                    message && <div className='alert alert-info'>{message}</div>
                    }
                    {
                      (error && !Array.isArray(error) ) ? <p className='alert alert-warning'>{error}</p>: '' 
                    }
                    
                    <form onSubmit={handleLogin} className="pt-3">
                      <div className="form-group">
                        <input type="text" className='form-control form-control-lg' placeholder="Username or Email" value={usernameOrEmail} onChange={(e) => setUsernameOrEmail(e.target.value)} />
                        {error && <span className='text text-danger text-small'>{error['email']}</span>}
                      </div>
                      <div className="form-group">
                        <input type="password" className='form-control form-control-lg' placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        {error && <span className='text text-danger text-small'>{error['password']}</span>}
                      </div>
                      <div className='mt-3'>
                        <button type="submit" className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'><i className='bi bi-box-arrow-in-right'></i> Sign In</button>
                        <div className="text-center mt-4 font-weight-light">
                            Don't have an account?
                            <NavLink className="text-muted font-weight-bold" to="/register">
                              <i className='bi bi-box-arrow-in-down-right ml-2'></i> Let's Join?
                            </NavLink>
                        </div>
                      </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
