import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import { Link } from 'react-router-dom';

export class Provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            modalTitle: "",
            ProviderId: 0,
            Name: "",
            Short: "",
            Status: "",
            ProviderIdFilter: "",
            ProviderNameFilter: "",
            providersWithoutFilter: [],
            isLoading: true,
        }
    }

    changeName = (e) => {
        this.setState({ Name: e.target.value });
    }

    changeShort = (e) => {
        this.setState({ Short: e.target.value });
    }

    changeStatus = (e) => {
        this.setState({ Status: e.target.value });
    }

    addClick() {
        this.setState({
            modalTitle: "Add Provider",
            ProviderId: 0,
            Name: "",
            Short: "",
            Status: "",
        });
    }

    editClick(Provider) {
        this.setState({
            modalTitle: "Edit Provider",
            ProviderId: Provider.id,
            Name: Provider.name,
            Status: Provider.status,
            Short: Provider.short
            // DepartmentName: dep.DepartmentName
        });
    }

    refreshList() {
        fetch(api_url.API_URL + 'providers')
            .then(this.setState({ isLoading: true }))
            .then(response => response.json())
            .then(res => {
                this.setState({
                    providers: res.data,
                    providersWithoutFilter: res.data,
                    isLoading: false
                });
            })
            .catch({ isLoading: false });
    }

    componentDidMount() {
        this.refreshList();
    }

    createClick() {
        fetch(api_url.API_URL + 'providers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.Name,
                status: this.state.Status,
                short: this.state.Short
            })
        })
            .then(this.setState({ isLoading: true }))
            .then(res => res.json())
            .then((result) => {
                document.getElementById('exampleModal').style.display = "none";
                this.refreshList();
            }, (error) => {
                alert('Failed');
                // {isLoading: true}
            })
    }

    updateClick() {
        fetch(api_url.API_URL + 'providers/' + this.state.ProviderId, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.Name,
                status: this.state.Status,
                short: this.state.Short
            })
        })
            .then(res => res.json())
            .then((result) => {
                // document.getElementById('exampleModal').hide();
                document.getElementById('exampleModal').style.display = "none";
                this.refreshList();
            }, (error) => {
                alert('Failed');
            })
    }


    render() {
        const {
            providers,
            modalTitle,
            ProviderId,
            Name,
            Short,
            Status,
            isLoading,
        } = this.state;

        if (isLoading) {
            return <div className='backface'><div className="loader"></div></div>;
        }
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    {/* <button type="button" 
                            className="btn btn-primary mt-4 mb-4 float-right" data-toggle="modal" data-target="#exampleModal"
                            onClick={() => this.addClick()}>
                            Add Provider
                        </button> */}
                    <div className='col-lg-12 grid-margin stretch-card'>
                        <div className='card'>
                            <div className='card-body'>
                            <Link className='btn btn-primary float-right' to={'/AddProvider'}><i className='bi bi-plus-square'></i> Add Provier</Link>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                Provider Id
                                            </th>
                                            <th>
                                                Provider Name
                                            </th>
                                            <th>
                                                Provider Short
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                Options
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {providers.map((Provider, index) =>
                                            <tr key={Provider.id}>
                                                <td>{index + 1}</td>
                                                <td>{Provider.name}</td>
                                                <td>{Provider.short}</td>
                                                <td>{(Provider.status) ? 'Active' : 'In Active'}</td>
                                                <td>{(Provider.status) ? <label className="badge badge-success">Active</label> : <label className="badge badge-danger">In Active</label>}</td>
                                                <td>
                                                    {/* <button type="button" className="btn btn-light mr-1" data-toggle="modal" data-target="#exampleModal" 
                                                        onClick={() => this.editClick(Provider)}>
                                                            <i className="bi bi-pencil-square"></i>
                                                    </button> */}
                                                    <Link to={`/provider/edit/${Provider.id}`} className='mr-3'><i className="bi bi-pencil-square"></i></Link>
                                                    <button type="button"
                                                        className="mr-3 btn btn-rounded btn-icon p-0 w-auto h-auto"
                                                        onClick={() => this.deleteClick(Provider.id)}>
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{modalTitle}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-lg-5'>
                                                        <div className="form-group text-left">
                                                            <span className="form-group-text">Name</span>
                                                            <input type="text" className="form-control"
                                                                value={Name}
                                                                onChange={this.changeName} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group text-left">
                                                            <span className="form-group-text">Short Name</span>
                                                            <input type="text" className="form-control"
                                                                value={Short}
                                                                onChange={this.changeShort} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group text-left">
                                                            <span className="form-group-text">Status</span>
                                                            <select className="form-select form-control"
                                                                onChange={this.changeStatus}
                                                                value={Status}>
                                                                <option value="">-</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">In Active</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                {ProviderId === 0 ?
                                                    <button type="button"
                                                        className="btn btn-primary float-start"
                                                        onClick={() => this.createClick()}
                                                    >Create</button>
                                                    : null}
                                                {ProviderId !== 0 ?
                                                    <button type="button"
                                                        className="btn btn-primary float-start"
                                                        onClick={() => this.updateClick()}
                                                    >Update</button>
                                                    : null}
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
