import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { api_url } from '../Constants/api_constant';

export class Company extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            modalTitle: "",
            CompanyId: 0,
            NameFirma: "",
            OwnerName: "",
            ShortName: "",
            Status: "",
            Phone: "",
            extraFields: {
                title: '',
                efval: '',
            },
            isLoading: '',
            CompanyIdFilter: "",
            CompanyNameFilter: "",
            companiesWithoutFilter: []
        }
    }

    changeNameFirma = (e) => {
        this.setState({ NameFirma: e.target.value });
    }

    changeExtraFields = (e) => {
        this.setState({ title: e.target.value, efValue: e.target.value, });
    }

    changeShortName = (e) => {
        this.setState({ ShortName: e.target.value });
    }

    changePhone = (e) => {
        this.setState({ Phone: e.target.value });
    }

    changeOwnerName = (e) => {
        this.setState({ OwnerName: e.target.value });
    }

    changeStatus = (e) => {
        this.setState({ Status: e.target.value });
    }

    addClick() {
        this.setState({
            modalTitle: "Add Company",
            CompanyId: 0,
            NameFirma: "",
            OwnerName: "",
            ShortName: "",
            Status: "",
            Phone: ""
        });
    }

    editClick(company) {
        this.setState({
            modalTitle: "Edit Comapny",
            CompanyId: company.id,
            NameFirma: company.name_firma,
            Status: company.status,
            Phone: company.phone,
            ShortName: company.short_name,
            OwnerName: company.owner_name
            // DepartmentName: dep.DepartmentName
        });
    }

    refreshList() {
        fetch(api_url.API_URL + 'companies')
            .then(this.setState({ isLoading: true }))
            .then(response => response.json())
            .then(res => {
                this.setState({
                    companies: res.data,
                    companiesWithoutFilter: res.data,
                    isLoading: false
                });
            });
    }

    componentDidMount() {
        this.refreshList();
    }

    createClick() {
        console.log(this.state);
        // fetch(api_url.API_URL + 'companies', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         name_firma: this.state.NameFirma,
        //         owner_name: this.state.OwnerName,
        //         status: this.state.Status,
        //         phone: this.state.Phone,
        //         short_name: this.state.ShortName
        //     })
        // })
        // .then(res => res.json())
        // .then((result) => {
        //     document.getElementById('exampleModal').style.display = "none";
        //     this.refreshList();
        // }, (error) => {
        //     alert('Failed');
        // })
    }

    updateClick() {
        fetch(api_url.API_URL + 'companies/' + this.state.CompanyId, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name_firma: this.state.NameFirma,
                owner_name: this.state.OwnerName,
                status: this.state.Status,
                phone: this.state.Phone,
                short_name: this.state.ShortName
            })
        })
            .then(this.setState({ isLoading: true }))
            .then(res => res.json())
            .then((result) => {
                // document.getElementById('exampleModal').hide();
                document.getElementById('exampleModal').style.display = "none";
                this.refreshList();
            }, (error) => {
                alert('Failed');
                this.setState({ isLoading: false })
            })
    }


    render() {
        const {
            companies,
            modalTitle,
            CompanyId,
            NameFirma,
            OwnerName,
            ShortName,
            Status,
            Phone,
            isLoading
        } = this.state;
        if (isLoading) {
            return <div className='backface'><div className="loader"></div></div>;
        }
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    {/* <button type="button" 
                            className="btn btn-primary mt-4 mb-4 float-right" data-toggle="modal" data-target="#exampleModal"
                            onClick={() => this.addClick()}>
                            Add Company
                        </button> */}
                    <div className='col-lg-12 grid-margin stretch-card'>
                        <div className='card'>
                            <div className='card-body'>
                                <Link className='btn btn-primary float-right' to={'/AddCompany'}><i className='bi bi-plus-square'></i> Add Company</Link>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                Company Id
                                            </th>
                                            <th>
                                                Company name
                                            </th>
                                            <th>
                                                Company Owner
                                            </th>
                                            <th>
                                                Company Phone
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                Options
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies.map((company, index) =>
                                            <tr key={company.id}>
                                                <td>{index + 1}</td>
                                                <td>{company.name_firma}</td>
                                                <td>{company.owner_name}</td>
                                                <td>{company.phone}</td>
                                                <td>{(company.status) ? <label className="badge badge-success">Active</label> : <label className="badge badge-danger">In Active</label>}</td>
                                                <td>
                                                    {/* <button type="button" className="btn btn-light mr-1" data-toggle="modal" data-target="#exampleModal" 
                                                            onClick={() => this.editClick(company)}>
                                                                <i className="bi bi-pencil-square"></i>
                                                        </button> */}

                                                    <Link to={`/companies/edit/${company.id}`} className='mr-3'><i className="bi bi-pencil-square"></i></Link>
                                                    <Link to={`/companies/view/${company.id}`} className='mr-3'><i className="bi bi-eye"></i></Link>
                                                    <Link to={`/selectprovider/${company.id}`} className='mr-3'><i className='bi bi-boxes'></i></Link>
                                                    <button type="button"
                                                        className="mr-3 btn btn-rounded btn-icon p-0 w-auto h-auto"
                                                        onClick={() => this.deleteClick(company.id)}>
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">{modalTitle}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group text-left">
                                                                <span className="form-group-text">Firma Name</span>
                                                                <input type="text" className="form-control"
                                                                    value={NameFirma}
                                                                    onChange={this.changeNameFirma} />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group text-left">
                                                                <span className="form-group-text">Owner name</span>
                                                                <input type="text" className="form-control"
                                                                    value={OwnerName}
                                                                    onChange={this.changeOwnerName} />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group text-left">
                                                                <span className="form-group-text">Phone</span>
                                                                <input type="text" className="form-control"
                                                                    value={Phone}
                                                                    onChange={this.changePhone} />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group text-left">
                                                                <span className="form-group-text">Short Name</span>
                                                                <input type="text" className="form-control"
                                                                    value={ShortName}
                                                                    onChange={this.changeShortName} />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group text-left">
                                                                <span className="form-group-text">Status</span>
                                                                <select className="form-select form-control"
                                                                    onChange={this.changeStatus}
                                                                    value={Status}>
                                                                    <option value="true">Active</option>
                                                                    <option value="false">In Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='row'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                {CompanyId === 0 ?
                                                    <button type="button"
                                                        className="btn btn-primary float-start"
                                                        onClick={() => this.createClick()}
                                                    >Create</button>
                                                    : null}
                                                {CompanyId !== 0 ?
                                                    <button type="button"
                                                        className="btn btn-primary float-start"
                                                        onClick={() => this.updateClick()}
                                                    >Update</button>
                                                    : null}
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
