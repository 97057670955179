import axios from 'axios';
import { api_url } from '../Constants/api_constant';

const RegisterController = {
    registerUser: async (registerData, setMessage,setErrorMessage) => {
      try {
        const response = await axios.post(`${api_url.API_URL}auth/company/register`, registerData);
        // Handle successful registration
        if(response.data.success && response.data.data) {
          setMessage('Registered!!');
        } else {
          setMessage(response.data.data);
        }
        console.log(response.data);
      } catch (error) {
        // Handle registration error
        if(error.response && error.response.data && error.response.data.data) {
          var errorObj = []
          error.response.data.data.forEach(element => {
            errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
          });
          setErrorMessage(errorObj);
        } else {
          setErrorMessage('Unable to register!!');
        }
        
      }
    }
};

export default RegisterController;

  