import React, { useEffect, useState } from 'react';
// import './App.css';
import { BrowserRouter, Route, Routes, NavLink } from 'react-router-dom';
import Logo from './logo.png';
import { Home } from './modules/Home';
import { AddCompany } from './modules/AddCompany';
import { AddProvider } from './modules/AddProvider';
import { EditCompany } from './modules/EditCompany';
import { EditProvider } from './modules/EditProvider';
import { Company } from './modules/Company';
import { Provider } from './modules/Provider';
import { SelectProvider } from './modules/SelectProvider';
import { Role } from './modules/Role';
import Login from './Views/Login';
import Register from './Views/Register';
import RequireAuth from './hoc/requireAuth';
import AdminSidebar from './Components/AdminSidebar';
import TopNavBar from './Components/TopNavBar';
import CustomerSidebar from './Components/Sidebar';
import SubscriberLogin from './Views/SubscriberLogin';
import SubscriberVippsLogin from './Views/SubscriberVippsLogin';
import { ViewCompany } from './modules/ViewCompany';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [sidebar, setSidebar] = useState("sidebar sidebar-offcanvas");
  
  const toggleSidebar = () => { 
    if(sidebar == 'sidebar sidebar-offcanvas active') {
      setSidebar("sidebar sidebar-offcanvas");
    } else {
      setSidebar("sidebar sidebar-offcanvas active");
    }
  };
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole');
    const companyId = localStorage.getItem('companyId');
    if (userRole) {
      setUserRole(userRole);
    }
    if (companyId) {
      setCompanyId(companyId);
    }
    if (authToken) {
      setIsAuthenticated(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
  };

  return (
    <BrowserRouter>
      <div className="App" id="outer-container">
        <div id="container-scroller">
          {isAuthenticated ? <TopNavBar logout={logout} toggleSidebar={toggleSidebar} isAuthenticated={isAuthenticated} logo={Logo} /> : ''}
          <div className='container-fluid page-body-wrapper pt-0'>
            
            {
              (isAuthenticated && userRole === 'Admin') ?
                  <AdminSidebar logout={logout} isAuthenticated={isAuthenticated} logo={Logo} sidebar={sidebar} />:
                  <CustomerSidebar logout={logout} companyId={companyId} isAuthenticated={isAuthenticated} logo={Logo} sidebar={sidebar} />
            }
            <Routes>
              <Route path='/' element={isAuthenticated ? <Home /> : <Login />}></Route>
              <Route path='/dashboard' element={isAuthenticated ? <Home /> : <Login />}></Route>
              <Route path='/subscriber/login' element={<SubscriberLogin />}></Route>
              <Route path='/subscriber/vipps-login' element={<SubscriberVippsLogin />}></Route>
              <Route path='/companies/edit/:id' element={isAuthenticated ? <EditCompany /> : <Login />}></Route>
              <Route path='/companies/view/:id' element={isAuthenticated ? <ViewCompany /> : <Login />}></Route>
              <Route path='/selectprovider/' element={isAuthenticated ? <SelectProvider /> : <Login />}></Route>
              <Route path='/selectprovider/:id' element={isAuthenticated ? <SelectProvider /> : <Login />}></Route>
              <Route path='/register' element={isAuthenticated ? <Home /> : <Register />}></Route>
              <Route path='/login' element={isAuthenticated ? <Home /> : <Login />}></Route>
              <Route
                path="/companies"
                element={
                  <RequireAuth>
                    <Company />
                  </RequireAuth>
                }
              />
              <Route
                path="/addcompany"
                element={
                  <RequireAuth>
                    <AddCompany />
                  </RequireAuth>
                }
              />
              {/* <Route path='/addcompany' element={isAuthenticated ? <AddCompany/> : <Login />}></Route> */}

              <Route
                path="/providers"
                element={
                  <RequireAuth>
                    <Provider />
                  </RequireAuth>
                }
              />
              <Route
                path="/addprovider"
                element={
                  <RequireAuth>
                    <AddProvider />
                  </RequireAuth>
                }
              />
              <Route
                path="/provider/edit/:id"
                element={
                  <RequireAuth>
                    <EditProvider />
                  </RequireAuth>
                }
              />
              {/* <Route path='/addprovider' element={isAuthenticated ? <AddProvider/> : <Login />}></Route>           */}
              {/* <Route path='/provider/edit/:id' element={isAuthenticated ? <EditProvider/> : <Login />}></Route>  */}
              <Route
                path="/roles"
                element={
                  <RequireAuth>
                    <Role />
                  </RequireAuth>
                }
              />
              <Route path='/home' element={isAuthenticated ? <Home /> : <Login />}></Route>
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
