import { Navigate, useNavigate } from "react-router-dom";
import {useState, useEffect} from 'react';



const handleResponse = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
};
const getUrlId = () => {
  const pathname = window.location.pathname;
  if(pathname) {
    return pathname.substring(pathname.lastIndexOf('/') + 1)
  }
};

const RedirectToUrlDirect = (url) => {
  if(url) {
    window.location.replace(url);
  }
};

export {handleResponse,getUrlId, RedirectToUrlDirect};
  