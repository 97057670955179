import React from 'react'
import { NavLink } from 'react-router-dom'

function CustomerSidebar(props) {
    return (
        <div>
            {
                props.isAuthenticated ?
                <nav className={props.sidebar} id="sidebar">
                    <ul className="nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/dashboard">
                                <i className='icon-grid menu-icon'></i>
                                <span className="menu-title">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#companies" aria-expanded="false" aria-controls="companies">
                                <i className="bi bi-building sub-menu mr-3"></i>
                                <span className="menu-title">Company</span>
                                <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="companies">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to={'/companies/view/' + props.companyId}>
                                            <i className='bi bi-eye mr-2'></i> View
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to={'/companies/edit/' + props.companyId}>
                                            <i className='bi bi-pencil mr-2'></i> Edit
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/selectprovider/' + props.companyId}>
                                <i className='bi bi-boxes mr-3'></i> Provider
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                    :
                    ''
            }
        </div>
    )
}

export default CustomerSidebar