import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import { getUrlId, RedirectToUrlDirect } from '../Utils/responseHandler';
import withRouter from '../hoc/withRouter';
import axios from 'axios';
const _ = require('lodash');



export class EditProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            title: "Extra Fields",
            formValues: [{ name: "", description: "", short: "", status: "", extra_fields: "" }],
            extraValues: [],
            provider_fields: [],
            messageError: "",
            messageSuccess: "",
            isLoading: true,
            navigate: this.props,
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    mainChange(i, e) {
        let formValues = this.state.formValues;
        formValues[0][e.target.name] = e.target.value;
        this.state.data[e.target.name] = e.target.value;
        this.setState({ formValues: formValues });
    }

    handleProviderValueChange(i, e) {
        let provider_fields = this.state.provider_fields;
        if (provider_fields[i].id) {
            provider_fields[i].value = e.target.value;

        } else {
            provider_fields[i]['value'] = e.target.value;
        }
        this.setState({ provider_fields });
    }

    handleProviderNameChange(i, e) {
        let provider_fields = this.state.provider_fields;
        if (provider_fields[i].id) {
            provider_fields[i].name = e.target.value;

        } else {
            provider_fields[i]['name'] = e.target.value;
        }
        this.setState({ provider_fields });
    }

    addFormFields() {
        this.setState(({
            provider_fields: [...this.state.provider_fields, {}]
        }))
    }
    async removeFormFields(i, el) {
        if (!_.isEmpty(el[i])) {
            console.log(el[i].id);
            let res = await
                axios.delete(api_url.API_URL + 'providers/fields/' + el[i].id)
                    .then(response => { console.log(response) })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
        }
        let provider_fields = this.state.provider_fields;
        provider_fields.splice(i, 1);
        this.setState({ provider_fields });
    }


    async handleSubmit(e) {
        e.preventDefault();
        let output = [];
        let mainvalue = this.state.formValues[0];
        let extvalue = this.state.provider_fields;
        mainvalue['extra_fields'] = extvalue;
        console.log(this.state.formValues[0])
        console.log(mainvalue)
        try {

            let res = await fetch(api_url.API_URL + 'providers/' + getUrlId(), {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mainvalue),
            });
            let resJson = await res.json();

            if (resJson.error === false) {
                this.setState({ messageError: JSON.stringify(resJson.data) });
            }
            if (resJson && resJson.success) {
                this.setState({ messageSuccess: "Provider has been registered!!" });
                window.location.replace('/providers');
            }
        } catch (err) {
            console.log(err);
            this.setState({ messageError: "Some error occured" });
        }
    }

    async fetchData() {
        const id = getUrlId();
        try {
            fetch(api_url.API_URL + 'providers/' + id)
                .then(response => response.json())
                .then(res => {
                    // console.log(res.data)
                    this.setState({
                        data: res.data,
                        formValues: [{
                            name: res.data.name,
                            description: res.data.description,
                            short: res.data.short,
                            status: res.data.status,
                        }],
                        provider_fields: res.data.provider_fields,
                        isLoading: false,
                    });
                }).catch(error => {
                    // console.error('Error fetching data:', error);
                    this.setState({ isLoading: false });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { name, description, short, status, extra_fields } = this.state.formValues[0];
        const { data, provider_fields, formValues, isLoading } = this.state;
        if (isLoading) {
            return <div className="loader"></div>;
        }
        if (!data) {
            return <div>No data found.</div>;
        }
        //    const { extrafield } = this.state.extraValues;
        //     console.log(extrafield)
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="card registration_form" >
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Provider</h3>
                        </div>
                        <form onSubmit={this.handleSubmit} >
                            <div className="modal-body">
                                <div className="message">
                                    {
                                        this.state.messageSuccess ? <p className='alert alert-success'>{this.state.messageSuccess}</p>
                                            : this.state.messageError ? <p className='alert alert-warning'>{this.state.messageError}</p>
                                                : null
                                    }
                                </div>
                                <div className='row' >
                                    <div className='col-lg-6'>
                                        <div className="form-group text-left">
                                            <label className="form-group-text">Name</label>
                                            <input type="text" className="form-control" name="name"
                                                value={data.name}
                                                onChange={e => this.mainChange('name', e)} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group text-left">
                                            <label className="form-group-text">Shortname</label>
                                            <input type="text" className="form-control" name="short" value={data.short}
                                                onChange={e => this.mainChange(formValues.short, e)} />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className="form-group text-left">
                                            <label className="form-group-text">Description</label>
                                            <input type="text" className="form-control" name="description" value={data.description}
                                                onChange={e => this.mainChange(formValues.description, e)} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group text-left">
                                            <label className="form-group-text">Status</label>
                                            <select className="form-select form-control" name="status"
                                                onChange={e => this.mainChange(formValues.status, e)}
                                                value={data.status}>
                                                <option value="true">Active</option>
                                                <option value="false">In Active</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.title ? <h4 className='text-left'>{this.state.title}</h4> : null}
                                {provider_fields.map((item, index) => (
                                    <div className='row form-group' key={index}>
                                        {/* <div className={index ? 'col-lg-5' : 'col-lg-6'}> */}
                                        <div className='col-lg-5'>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name='name'
                                                value={(item.name) ?? ''}
                                                onChange={(e) => this.handleProviderNameChange(index, e)}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            {
                                                (item.type == 'textarea') ?
                                                    <textarea type={(item.type) ? item.type : "text"}
                                                        className="form-control"
                                                        name='value'
                                                        value={item.value ?? ''}
                                                        onChange={(e) => this.handleProviderValueChange(index, e)}
                                                    ></textarea>
                                                    :
                                                    <input
                                                        type={(item.type) ? item.type : "text"}
                                                        className="form-control"
                                                        name='value'
                                                        value={item.value}
                                                        onChange={(e) => this.handleProviderValueChange(index, e)}
                                                    />
                                            }
                                        </div>
                                        {
                                            index ?
                                                <div className='col-lg-1'>
                                                    <button type="button" className="btn btn-icon btn-rounded btn-warning remove float-right" onClick={() => this.removeFormFields(index, provider_fields)}><i className='bi bi-trash-fill'></i></button>
                                                </div>
                                                : <div className='col-lg-1'></div>
                                        }
                                    </div>
                                ))
                                }
                                <button className="btn btn-primary btn-icon btn-rounded float-right ml-2 mb-2" type="button" onClick={() => this.addFormFields()}><i className='bi bi-plus'></i></button>
                                <div className='clearfix'></div>
                            </div>

                            <div className="modal-footer">
                                <button className="btn btn-primary float-left" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(EditProvider);

