import React, { Component } from "react";

export class Home extends Component {
    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-md-12 grid-margin">
                            <div className="row">
                                <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                                    <div className="mb-5">
                                        <div className="">
                                            <div className=""><h4>Dashboard</h4></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 grid-margin transparent">
                                        <div className="card card-light-danger">
                                            <div className="card-body">
                                                <p className="mb-4">Total Companies</p>
                                                <p className="fs-30 mb-2">10</p>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-3 mb-4 grid-margin transparent">
                                    <div className="card card-dark-blue">
                                        <div className="card-body">
                                            <p className="mb-4">Total Providers</p>
                                            <p className="fs-30 mb-2">5</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4 mb-lg-0 grid-margin transparent">
                                    <div className="card card-light-blue">
                                        <div className="card-body">
                                            <p className="mb-4">Total Subscriber Sessions</p>
                                            <p className="fs-30 mb-2">34040</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}