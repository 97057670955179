import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import axios from 'axios';
import { getUrlId } from '../Utils/responseHandler';
import _ from 'lodash';


export class SelectProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCompany: '',
            selectedProvider: '',
            selectedProviderStatus: '',
            statusEnabled: 'hide',
            companies: [],
            providers: [],
            companyproviders: [],
            label: '',
            messageSuccess: "",
            messageError: "",
            role: localStorage.getItem('userRole'),
            company_id: localStorage.getItem('companyId'),
            companyName: localStorage.getItem('companyName')
        }
    }

    availableProviders = async (e) => {
        try {
            let res = await fetch(api_url.API_URL + 'providers/', {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            let resJson = await res.json();
            this.setState({
                providers: resJson.data,
            })
        } catch (error) {
            console.log(error);
        }
    }

    availableCompanies = async (e) => {
        try {
            let res = await fetch(api_url.API_URL + 'companies/', {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            let resJson = await res.json();
            this.setState({
                companies: resJson.data,
            })
        } catch (error) {
            console.log(error);
        }
    }

    fetchCompanyProviders = (selectedCompany, selectedProvider) => {
        const companyId = selectedCompany;
        const providerId = selectedProvider;
        console.log(companyId);
        console.log(providerId);
        var url = api_url.API_URL + 'companies/provider-list';
        var body = {
            'company_id': companyId,
            'provider_id': providerId,
        };
        axios.post(url, body)
            .then(response => {
                // Assuming the API response returns an array of data
                this.setState({ companyproviders: response.data.data });
                this.setState({ statusEnabled: 'show' });
            })
            .catch(error => {
                this.setState({ statusEnabled: 'hide' });
                console.error('Error fetching data:', error);
            });
    }


    handleCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        this.setState({ selectedCompany });
        this.setState({ selectedProvider: '' });
        this.setState({ selectedProviderStatus: '' });
        this.setState({ companyproviders: [] })
    }

    handleProviderStatusChange = (e) => {
        console.log('Status');
        console.log(e.target.value);
        const selectedProviderStatus= e.target.value;
        this.setState({ selectedProviderStatus });
        console.log(this.state.selectedProviderStatus);
    }


    handleProviderChange = (event) => {
        const selectedProvider = event.target.value;
        const selectedCompany = this.state.selectedCompany;
        this.setState({ selectedProvider });
        this.fetchCompanyProviders(selectedCompany, selectedProvider);
    }

    handleInputChange = (event, name) => {
        const { value } = event.target;
        const { companyproviders } = this.state;

        // Find the object with the matching name
        const updatedData = companyproviders.map(obj => {
            if (obj.name === name) {
                return { ...obj, value: value };
            }
            return obj;
        });

        // Update the state with the modified data array
        this.setState({ companyproviders: updatedData });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(this.state.companyproviders);
        let componyprovidersfield = this.state.companyproviders;
        //console.log(componyprovidersfield);
        let arrayextrafields = componyprovidersfield.reduce((acc, obj) => {
            let { name, value } = obj;
            acc.push({ [name]: value });
            return acc;
        }, []);
        let requiredvalue = Object.assign({}, ...arrayextrafields);
        //console.log(requiredvalue);

        try {
            let res = await fetch(api_url.API_URL + 'companies/provider', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    provider_id: this.state.selectedProvider,
                    company_id: this.state.selectedCompany,
                    status: this.state.selectedProviderStatus,
                    extra_fields: requiredvalue
                }),
            });
            let resJson = await res.json();
            //console.log(resJson); 
            if (resJson.error === false) {
                this.setState({ messageError: JSON.stringify(resJson.data) });
            }
            if (resJson && resJson.success) {
                this.setState({ messageSuccess: "Company with Provider has been registered!!" });
            }

        } catch (error) {
            console.log(error);
            this.setState({ messageError: error });
        }
    }

    componentDidMount() {
        var companyId = _.isEmpty(getUrlId()) ? this.state.company_id : getUrlId();

        if ((!companyId || companyId) && this.state.role === 'Admin') {

            this.availableCompanies();
            this.availableProviders();
            this.setState({ messageSuccess: "", selectedCompany: companyId });
        }
        if (companyId && this.state.role === 'customer') {
            this.setState({
                companies: [
                    {
                        "id": companyId,
                        "name_firma": this.state.companyName,
                    }
                ],
                selectedCompany: companyId
            });
            this.availableProviders();
            this.setState({ messageSuccess: "" });
        }
    }
    render() {
        const { selectedCompany, selectedProvider,selectedProviderStatus, companies,statusEnabled, companyproviders, providers, messageError, messageSuccess } = this.state;
        const { label } = this.state.companyproviders;
        console.log(companyproviders);
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='card'>
                        <div className='modal-header'>
                            <h6>Manage Provider</h6>
                        </div>
                        <div className='container card-body'>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <label className="form-group-text">Company</label>
                                        <select className='form-control'
                                            value={selectedCompany}
                                            disabled={(selectedCompany && this.state.role !== 'Admin')}
                                            onChange={this.handleCompanyChange}
                                        >
                                            <option>Select Company</option>
                                            {
                                                companies.map((ele) => (
                                                    <option key={ele.id} value={ele.id}>{ele.name_firma}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-group-text">Provider</label>
                                        <select className='form-control'
                                            value={selectedProvider}
                                            onChange={this.handleProviderChange}
                                        >
                                            <option>Select Provider</option>
                                            {
                                                providers.map((ele) => (
                                                    <option key={ele.id} value={ele.id}>{ele.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={'col-lg-4 ' + statusEnabled }> 
                                                <div className="form-group text-left">
                                                    <label className="form-group-text">Status</label>
                                                    <select className="form-select form-control"
                                                        name="status"
                                                        value={selectedProviderStatus}
                                                        onChange={this.handleProviderStatusChange}
                                                    >
                                                        <option value="true">Active</option>
                                                        <option value="false">In Active</option>
                                                    </select>
                                                    {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['status']}</span>}
                                                </div>

                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className='form-group'>
                                    <div className="message">
                                        {
                                            this.state.messageSuccess ? <p className='alert alert-success'>{this.state.messageSuccess}</p>
                                                : this.state.messageError ? <p className='alert alert-warning'>{this.state.messageError}</p>
                                                    : null
                                        }
                                    </div>
                                    {companyproviders.length > 0 ? (
                                        <div className=''>
                                            {companyproviders.map(item => (
                                                <div className='row' key={item.name} >
                                                    <div className='form-group col-lg-4'>
                                                        <label> {
                                                            (item.name.charAt(0).toUpperCase()
                                                                + item.name.slice(1)).replace('_', ' ')} </label>
                                                    </div>
                                                    <div className='form-group col-lg-8'>
                                                        <input
                                                            required
                                                            type="text"
                                                            key={item.name}
                                                            className="form-control"
                                                            name={item.name}
                                                            value={item.id ? item.value : label}
                                                            onChange={e => this.handleInputChange(e, item.name)}
                                                        />
                                                    </div>
                                                </div>

                                            ))}
                                        </div>

                                    ) : (
                                        <div className='col-lg-12'><div className='form-group'><p>No data to display.</p></div></div>
                                    )}
                                    <div className="">
                                        <button type="submit"
                                            className="btn btn-primary float-start"
                                        >Create</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
