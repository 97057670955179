import React, { Component } from 'react';
// import { useNavigate } from 'react-router-dom';
import { api_url } from '../Constants/api_constant';

export class AddCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: [{ firmaname: "", ownername: "", shortname: "", status: "", phone: "" , apikey: "", production: ""}],
            extraValues: [{ name: "", value: "" }],
            messageError: "",
            messageSuccess: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    // showMessage = (e) => {
    //     this.setState({ showMessage: e.target.value });
    // }


    mainChange(i, e) {
        let formValues = this.state.formValues;
        formValues[i][e.target.name] = e.target.value;
        this.setState({ formValues });
    }

    handleChange(i, e) {
        let extraValues = this.state.extraValues;
        extraValues[i][e.target.name] = e.target.value;
        this.setState({ extraValues });
    }

    addFormFields() {
        this.setState(({
            extraValues: [...this.state.extraValues, { name: "", value: "" }]
        }))
    }

    removeFormFields(i) {
        let extraValues = this.state.extraValues;
        extraValues.splice(i, 1);
        this.setState({ extraValues });
    }





    handleSubmit = async (e) => {

        e.preventDefault();
        //let output = [];        
        let mainvalue = this.state.formValues[0];
        console.log(this.state.extraValues);
        //let extrafield = JSON.stringify(this.state.extraValues);
        // let requiredfield = JSON.parse(extrafield);
        //console.log(this.state.extraValues);
        // let extvalue = JSON.stringify(this.state.extraValues);
        // let arrayextvalue = JSON.parse(extvalue);
        // let convertedArray = arrayextvalue.reduce((acc, obj) => {
        //     let { extraname, extravalue } = obj;
        //     acc.push({ [extraname]: extravalue });
        //     return acc;
        // }, []);
        // let requiredvalue = {};
        // convertedArray.forEach(item => {
        //     let key = Object.keys(item)[0];
        //     let value = item[key];
        //     requiredvalue[key] = value;
        // });
        // output.push({            
        //     extra_fields : requiredvalue,
        //     name_firma: mainvalue.firmaname,
        //     owner_name: mainvalue.ownername,
        //     phone: mainvalue.phone,          
        //     short_name: mainvalue.shortname,
        //     status: mainvalue.status
        // });
        //  console.log(JSON.stringify(output));

        //alert(JSON.stringify(output));  

        try {
            var postdata = JSON.stringify({
                name_firma: mainvalue.firmaname,
                owner_name: mainvalue.ownername,
                phone: mainvalue.phone,
                short_name: mainvalue.shortname,
                status: mainvalue.status,
                apikey: mainvalue.apikey,
                production: mainvalue.production,
                extra_fields: this.state.extraValues,
            });
            console.log(postdata);

            let res = await fetch(api_url.API_URL + 'companies', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postdata,
            });
            let resJson = await res.json();
            console.log(resJson);
            if (resJson.error === false) {
                if(resJson.data) {
                    var errorObj = []
                    resJson.data.forEach(element => {
                        errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
                    });
                    this.setState({ messageError: errorObj});
                } else {
                    this.setState({ messageError: resJson.data});
                }
            }
            // if (resJson.error === false) {
            //     this.setState({ messageError: JSON.stringify(resJson.data) });
            // }
            if (resJson.success == true) {
                this.setState({ messageSuccess: "Company has been registered!!" });
                this.setState({
                    formValues: [{ firmaname: "", ownername: "", shortname: "", status: "", phone: "" , apikey: "", production: ""}],
                    // extraValues: [{ extraname: "", extravalue: "" }]
                    extraValues: [{ name: "", value: "" }]
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({ messageError: "Some error occured" });

        }
    };


    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="card registration_form" >
                        <div className="modal-header">
                            <h3 className='card-heading'>New Company</h3>
                        </div>
                        <form onSubmit={this.handleSubmit} >
                            <div className="modal-body">
                                <div className="message">
                                    {
                                        this.state.messageSuccess ? <p className='alert alert-success'>{this.state.messageSuccess}</p>
                                        : (this.state.messageError && !Array.isArray(this.state.messageError) ) ? <p className='alert alert-warning'>{this.state.messageError}</p> 
                                                : null
                                    }
                                </div>
                                {this.state.formValues.map((ele, inx) => (
                                    <div className='row' key={inx} >
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Firma Name</label>
                                                <input type="text" className="form-control" name="firmaname"
                                                    value={ele.firmaname}
                                                    onChange={e => this.mainChange(inx, e)} />
                                                    {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['name_firma']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Owner name</label>
                                                <input type="text" className="form-control" name="ownername"
                                                    value={ele.ownername}
                                                    onChange={e => this.mainChange(inx, e)} />
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['owner_name']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Phone</label>
                                                <input type="text" className="form-control" name="phone"
                                                    value={ele.phone}
                                                    onChange={e => this.mainChange(inx, e)} />
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['phone']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Short Name</label>
                                                <input type="text" className="form-control" name="shortname"
                                                    value={ele.shortname}
                                                    onChange={e => this.mainChange(inx, e)} />
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['short_name']}</span>}
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Status</label>
                                                <select className="form-select form-control" name="status"
                                                    onChange={e => this.mainChange(inx, e)}
                                                    value={ele.status}>
                                                    <option value="true">Active</option>
                                                    <option value="false">In Active</option>
                                                </select>
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['status']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Production Mode</label>
                                                <select className="form-select form-control" name="production"
                                                    onChange={e => this.mainChange(inx, e)}
                                                    value={ele.production}>
                                                    <option value="live">Live</option>
                                                    <option value="test">Test</option>
                                                </select>
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['production']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group text-left">
                                                <label className="form-group-text">Api key</label>
                                                <input type="text" className="form-control" name="apikey"
                                                    value={ele.apikey}
                                                    onChange={e => this.mainChange(inx, e)} />
                                                {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['apikey']}</span>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <h4 className='text-left'>Extra fields</h4>
                                {this.state.extraValues.map((element, index) => (
                                    <div className='row form-group' key={index} >
                                        <div className='col-lg-6'>
                                            <input type="text" className="form-control" name="name" placeholder='name add here..'
                                                value={element.name || ""}
                                                onChange={e => this.handleChange(index, e)} />
                                        </div>
                                        <div className='col-lg-6'>
                                            <input type="text" className="form-control" name="value" placeholder='value add here..'
                                                value={element.value || ""}
                                                onChange={e => this.handleChange(index, e)} />
                                            {
                                                index ?
                                                    <button type="button" className="btn btn-warning btn-icon btn-rounded float-right mt-1 remove" onClick={() => this.removeFormFields(index)}><i className='bi bi-dash-lg'></i></button>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                ))}
                                <div className="button-section">
                                    <button className="btn btn-primary btn-icon btn-rounded float-right" type="button" onClick={() => this.addFormFields()}><i className='bi bi-plus'></i></button>
                                </div>
                                <div className='clearfix'></div>
                            </div>

                            <div className="modal-footer">
                                <button className="btn btn-primary float-left" type="submit">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
