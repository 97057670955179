import React, { useState } from 'react';
import RegisterController from '../Controllers/RegisterController';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../logo.png';

const Register = () => {
    const [username, setUsername] = useState('');
    const [name_firma, setNameFirma] = useState('');
    const [first_name, setFirstName] = useState('');
    const [middle_name, setMiddleName] = useState('');
    const [last_name, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [organization_no, setOrganizationNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        const registerData = {
            username: username,
            name_firma: name_firma,
            email: email,
            password: password,
            phone: phone,
            organization_no: organization_no,
            mobile: mobile,
            address: address,
            first_name: first_name,
            last_name: last_name,
            middle_name: middle_name
        };
        console.log(registerData);

        await RegisterController.registerUser(registerData, setMessage, setErrorMessage, () => {
            navigate('/register'); // Redirect to home view
        });
    };
    return (
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-8 mx-auto">
                            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div class="brand-logo">
                                    <img src={Logo} alt="logo" />
                                </div>
                                <h4>New here?</h4>
                                <h6 class="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                                {message && <div className='alert alert-info'>{message}</div>}
                                <form onSubmit={handleRegister} className="pt-3">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='form-group'>
                                                <label className='form-label'>Firma Name</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="Firma Name"
                                                    value={name_firma}
                                                    onChange={(e) => setNameFirma(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['name_firma']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='form-group'>
                                                <label className='form-label'>Organization Number</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="Organization Number"
                                                    value={organization_no}
                                                    onChange={(e) => setOrganizationNumber(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['organization_no']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>First Name</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="First Name"
                                                    value={first_name}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['first_name']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>Middle Name</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="Middle Name"
                                                    value={middle_name}
                                                    onChange={(e) => setMiddleName(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['middle_name']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>Last Name</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="Last Name"
                                                    value={last_name}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['last_name']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>Username</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['username']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>Email</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['email']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='form-group'>
                                                <label className='form-label'>Password</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['password']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='form-group'>
                                                <label className='form-label'>Address</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="address"
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['address']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='form-group'>
                                                <label className='form-label'>Mobile</label>
                                                <input type="text"
                                                    className='form-control'
                                                    placeholder="Mobile"
                                                    value={mobile}
                                                    onChange={(e) => setMobile(e.target.value)}
                                                />
                                                {error && <span className='text text-danger text-small'>{error['mobile']}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>


                                        </div>
                                    </div>

                                    <button type="submit" className='btn btn-success'><i className='bi bi-box-arrow-in-down-right'></i> Let's Join</button>
                                    <NavLink className="btn btn-outline btn-outline-secondary ml-2" to="/login">
                                        <i className='bi bi-box-arrow-in-right'></i> Already Joined?
                                    </NavLink>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

