import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';

export class Role extends Component {
        constructor(props) {
            super(props);
            this.state = {
                roles: [],
                modalTitle: "",
                RoleId: 0,
                Name: "",
                Description: "",
                roleIdFilter: "",
                roleNameFilter: "",
                rolesWithoutFilter: []
            }
        }

        changeName = (e) => {
            this.setState({ Name: e.target.value });
        }
        
        changeDescription = (e) => {
            this.setState({ Description: e.target.value });
        }

        addClick() {
            this.setState({
                modalTitle: "Add role",
                RoleId: 0,
                Name: "",
                Description: "",
            });
        }

        editClick(role) {
            this.setState({
                modalTitle: "Edit role",
                RoleId: role.id,
                Name: role.name,
                Description: role.description,
            });
        }

        refreshList() {
            fetch(api_url.API_URL + 'roles')
                .then(response => response.json())
                .then(res => {
                    this.setState({ 
                        roles: res.data, 
                        rolesWithoutFilter: res.data });
                });
        }

        componentDidMount() {
            this.refreshList();
        }

        createClick() {
            fetch(api_url.API_URL + 'roles', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.state.Name,
                    description: this.state.Description
                })
            })
                .then(res => res.json())
                .then((res) => {
                    const modal = document.getElementById('exampleModal');
                    modal.style.display = "none";
                    // const modal = document.getElementById('exampleModal').style.display = "none";
                    const ele = document.getElementsByClassName('modal-backdrop fade show');
                    console.log(ele)
                    this.refreshList();
                }, (error) => {
                    alert('Failed');
                })
        }

        updateClick(e) {
            fetch(api_url.API_URL + 'roles/' + this.state.RoleId, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.state.Name,
                    description: this.state.Description
                })
            })
                .then(res => res.json())
                .then((res) => {
                    document.getElementById('exampleModal').style.display = "none";
                    const modal = document.getElementsByClassName('modal');
                    const role = document.getElementsByTagName('role');
                    const ele = document.getElementsByClassName('modal-backdrop');
                    const modalopen = document.getElementsByClassName('modal-open');
                    const dialogElement = document.querySelector('[role="dialog"]');
                    const ariaElement = document.querySelector('[aria-modal="true"]');
                    // const ariaHiddenElement = document.querySelector('[aria-hidden="true"]');
                    dialogElement.removeAttribute('role');
                    ariaElement.removeAttribute('aria-modal');
                    modal[0].setAttribute('aria-hidden', 'true');
                    modal[0].classList.remove('fade');
                    modal[0].classList.remove('show');
                    ele[0].classList.remove('fade');
                    ele[0].classList.remove('show');
                    ele[0].classList.remove('modal-backdrop');
                    console.log(role[0]);
                    modalopen[0].classList.remove('modal-open');
                    this.refreshList();
                }, (error) => {
                    alert('Failed');
                })
        }
    
    
        render() { 
            const {
                roles,
                modalTitle,
                RoleId,
                Name,
                Description,
            } = this.state;
            return (
                <div className="container">
                    <button type="button" 
                        className="btn btn-primary mt-4 mb-4 float-right" data-toggle="modal" data-target="#exampleModal"
                        onClick={() => this.addClick()}  >
                        <i className='bi bi-plus-square'></i> Add Role
                    </button>
                    <table className="table table-striped mt-4">
                        <thead>
                            <tr>
                                <th>
                                    Role Id
                                </th>
                                <th>
                                    Role Name
                                </th>
                                <th>
                                    Role Description
                                </th>
                                <th>
                                    Options
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map(role =>
                                <tr key={role.id}>
                                    <td>{role.id}</td>
                                    <td>{role.name}</td>
                                    <td>{role.description}</td>
                                    <td>
                                        <button type="button" className="btn btn-light mr-1" data-toggle="modal" data-target="#exampleModal" 
                                            onClick={() => this.editClick(role)}>
                                                 <i className="bi bi-pencil-square"></i>
                                        </button>
                                        <button type="button"
                                            className="btn btn-light mr-1"
                                            onClick={() => this.deleteClick(role.id)}>
                                                <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{modalTitle}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="form-group text-left">
                                                    <span className="form-group-text">Name</span>
                                                    <input type="text" className="form-control"
                                                        value={Name}
                                                        onChange={this.changeName} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group text-left">
                                                    <span className="form-group-text">Short Name</span>
                                                    <input type="text" className="form-control"
                                                        value={Description}
                                                        onChange={this.changeDescription} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                            {RoleId === 0 ?
                                            <button type="button"
                                                className="btn btn-primary float-start"
                                                onClick={() => this.createClick()}
                                            >Create</button>
                                            : null}
                                            {RoleId !== 0 ?
                                                <button type="button"
                                                    className="btn btn-primary float-start"
                                                    onClick={() => this.updateClick()}
                                                >Update</button>
                                                : null}
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            );
        }
}
