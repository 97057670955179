import axios from 'axios';
import { api_url } from '../Constants/api_constant';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import logResponse from '../Utils/logger';
 

const LoginController = {
    loginUser: async (loginData, setIsAuthenticated, setMessage, setErrorMessage) => {
      try {
        const response = await axios.post(`${api_url.API_URL}auth/company/login`, loginData);

        if(response.data.data && response.data.data.token) {
          localStorage.setItem('authToken', response.data.data.token);
          setIsAuthenticated(true);
          const decodedToken = jwt_decode(response.data.data.token);
          const role = decodedToken.user.role;
          const company = decodedToken.user.company;

          const company_id = decodedToken.user.company_id;
          const userRole = localStorage.setItem('userRole', role);
          const companyId = localStorage.setItem('companyId', company_id);         
          const companyName = localStorage.setItem('companyName', company);         
          localStorage.setItem('company', company);
          setMessage('Login successful');
        }
        setMessage('Login successful');
      } catch (error) {
        console.log(error.response)
        if(error.response && error.response.data && error.response.data) {
          var errorObj = []
          if(Array.isArray(error.response.data.data)) {
            error.response.data.data.forEach(element => {
              errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
            });
          } else {
              errorObj = error.response.data.data;
          }
          setErrorMessage(errorObj);
        } else {
          setErrorMessage('Unable to login!!');
        }
      }
    }
  };

  
export default LoginController;

  