import React, { Component } from 'react';
import { api_url } from '../Constants/api_constant';
import { Link } from 'react-router-dom';

export class AddProvider extends Component {
        constructor(props) {
            super(props);
            this.state = {
                ProviderId: 0,
                formValues: [{ name: "", description : "" ,short: "", status : ""}],
                extraValues: [{ extraname: "", extravalue : "" }],
                messageSuccess: "",
                messageError: []
            }
            this.handleSubmit = this.handleSubmit.bind(this);
        } 
        mainChange(i, e) {
            let formValues = this.state.formValues;
            formValues[i][e.target.name] = e.target.value;
            this.setState({ formValues });
        }
    
        handleChange(i, e) {
            let extraValues = this.state.extraValues;
            extraValues[i][e.target.name] = e.target.value;
            this.setState({ extraValues });
        }

        addFormFields() {
            this.setState(({
                extraValues: [...this.state.extraValues, { extraname: "", extravalue: "" }]
            }))
        }
    
        removeFormFields(i) {
            let extraValues = this.state.extraValues;
            extraValues.splice(i, 1);
            this.setState({ extraValues });
        }

        handleSubmit = async (e) => {
            e.preventDefault(); 
            let mainvalue = this.state.formValues[0]; 
            let extravalue = JSON.stringify(this.state.extraValues); 
            let arrayextvalue = JSON.parse(extravalue); 
            //console.log(arrayextvalue);
            let convertedArray = arrayextvalue.reduce((acc, obj) => {
                let { extraname, extravalue } = obj;
                acc.push({ [extraname]: extravalue });
                return acc;
            }, []);
            let requiredvalue = {};
            convertedArray.forEach(item => {
                let key = Object.keys(item)[0];
                let value = item[key];
                requiredvalue[key] = value;
            }); 
            try {
                let res = await fetch(api_url.API_URL + 'providers', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: mainvalue.name,                              
                        short: mainvalue.short,
                        status: mainvalue.status,
                        description: mainvalue.description,
                        extra_fields : requiredvalue,
                    }),
                });
                let resJson = await res.json();      
                if (resJson.error === false) {
                    if(resJson.data) {
                        var errorObj = []
                        resJson.data.forEach(element => {
                            errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
                        });
                        this.setState({ messageError: errorObj});
                    } else {
                        this.setState({ messageError: resJson.data});
                    }
                }
                if (resJson && resJson.success) { 
                    this.setState({ messageSuccess:  "Provider has been registered!!"});  
                    this.setState({
                        formValues: [{ name: "", description : "" ,short: "", status : ""}],
                        extraValues: [{ extraname: "", extravalue : "" }]
                    })
                    // document.getElementById("provider_form").reset();           
                }
            } catch (err) {
                console.log(err);
                this.setState({ messageError:  "Some error occured"});     
     
            }
        };
        render() { 
            return (
                <div className="main-panel">
                    <div className="content-wrapper provider_wrap">
                        <div className="card registration_form" >
                            <div className="modal-header">
                                <h3 className="card-heading">New Provider</h3> 
                            </div>
                            <form onSubmit={this.handleSubmit} id='provider_form'>   
                                <div className="modal-body">
                                    <div className="message"> 
                                        {
                                            this.state.messageSuccess ? <p className='alert alert-success'>{this.state.messageSuccess}</p> 
                                            : (this.state.messageError && !Array.isArray(this.state.messageError) ) ? <p className='alert alert-warning'>{this.state.messageError}</p> 
                                            : null
                                        } 
                                    </div> 
                                        {this.state.formValues.map((ele, inx) => (
                                            <div className='row' key={inx}>
                                                <div className='col-lg-6'>
                                                    <div className="form-group text-left">
                                                        <label className="form-group-text">Name</label>
                                                        <input type="text" className="form-control" name="name"
                                                            value={ele.name}
                                                            onChange={e =>this.mainChange(inx, e)} />
                                                        {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['name']}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group text-left">
                                                        <label className="form-group-text">Short Name</label>
                                                        <input type="text" className="form-control" name ="short"
                                                            value={ele.short}
                                                            onChange={e =>this.mainChange(inx, e)} />
                                                        {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['short']}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className="form-group text-left">
                                                        <label className="form-group-text">Description</label>  
                                                        <textarea className="form-control" name ="description" rows="4"
                                                            value={ele.description}
                                                            onChange={e =>this.mainChange(inx, e)} />
                                                        {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['description']}</span>}
                                                    </div>
                                                </div>
                                                
                                                <div className='col-lg-6'>
                                                    <div className="form-group text-left">
                                                        <label className="form-group-text">Status</label>
                                                        <select className="form-select form-control"
                                                            onChange={e =>this.mainChange(inx, e)} name="status"
                                                            value={ele.status}>
                                                            <option value="">-</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">In Active</option>
                                                        </select>
                                                        {this.state.messageError && <span className='text text-danger text-small'>{this.state.messageError['status']}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h4 className='text-left'>Extra fields</h4>
                                        {this.state.extraValues.map((element, index) => (
                                            <div className='row form-group' key={index} >
                                                <div className='col-lg-6'>
                                                    <input type="text" className="form-control" name ="extraname" placeholder='name add here..'
                                                        value={element.extraname || ""}
                                                        onChange={e => this.handleChange(index, e)} />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <input type="text" className="form-control" name ="extravalue" placeholder='value add here..'
                                                        value={element.extravalue || ""}
                                                        onChange={e => this.handleChange(index, e)} />
                                                    {
                                                        index ? 
                                                        <button type="button"  className="btn btn-warning btn-icon btn-rounded float-right mt-1 remove" onClick={() => this.removeFormFields(index)}><i className='bi bi-dash-lg'></i></button> 
                                                        : null
                                                    }
                                                </div> 
                                            </div>
                                        ))}
                                        <button className="btn btn-primary btn-icon btn-rounded float-right" type="button" onClick={() => this.addFormFields()}><i className='bi bi-plus'></i></button>
                                        <div className='clearfix'></div>
                                    </div>
                                <div className="modal-footer">                                    
                                        <button type="submit"
                                            className="btn btn-primary float-start"                                       
                                        >Create</button>    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
}
